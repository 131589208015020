import { useNavigate } from '@solidjs/router';
import { For, createSignal, onMount, Show } from 'solid-js';
import IconDefaultUnit from '~/assets/images/home/defaultUnit.svg';
import IconRentalArea from '~/assets/images/home/rentalArea.svg';
import IconWhiteLocation from '~/assets/images/home/whiteLocation.svg';
import { FEATURED_RENTAL_LISTINGS, FEATURED_RENTAL_LISTINGS_SUBTITLE, PINNED } from '~/assets/strings';
import { Arrow } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { CompanyPinnedUnitListingPresenter } from '~/presenters/CompanyPinnedUnitListingPresenter';
import { GetPinnedUnitsUseCase } from '~/use-cases/company-unit-listing/getPinnedUnitUseCase';

export const NewlyListedRentals = () => {
  const [currentIndex, setCurrentIndex] = createSignal(1);
  const navigate = useNavigate();
  const { execute } = useUseCase(GetPinnedUnitsUseCase);
  const { model } = usePresenter(CompanyPinnedUnitListingPresenter);

  const getPinnedUnits = (index: number) => {
    execute({
      Page: index,
      PageSize: 4,
      Pinned: true,
    });
  };

  onMount(() => {
    getPinnedUnits(1);
  });

  const handleChangeIndex = (index: number) => {
    setCurrentIndex(index);
    getPinnedUnits(index);
  };

  const Bottom = (address: string, area: string) => {
    return (
      <div
        style={{
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 95.24%)',
        }}
        class="absolute bottom-0 left-0 flex w-full flex-row flex-wrap justify-between gap-3  px-5 py-4 text-white">
        <div class="flex items-center gap-2 text-base font-medium ">
          <img width={24} src={IconWhiteLocation} alt="" />
          {address}
        </div>
        <div class="flex items-center gap-2 text-base  font-medium">
          <img width={24} src={IconRentalArea} alt="" />
          {area}
        </div>
      </div>
    );
  };

  return (
    <div class="flex w-full justify-center pt-[103px]">
      <div class="w-max-container-width">
        <div class="flex justify-between">
          <div>
            <div class="flex gap-3">
              <div class="text-[42px] font-semibold text-navy">{FEATURED_RENTAL_LISTINGS}</div>
            </div>
            <div class="mt-[13px] w-[570px] text-[18px] text-[#93A7BD]">{FEATURED_RENTAL_LISTINGS_SUBTITLE}</div>
          </div>

          <div class="flex items-center gap-2">
            <Arrow direction="left" disabled={currentIndex() === 1} onClick={() => handleChangeIndex(currentIndex() - 1)} />
            <Arrow
              direction="right"
              disabled={currentIndex() === model()?.totalPages}
              onClick={() => handleChangeIndex(currentIndex() + 1)}
            />
          </div>
        </div>

        <div class="mt-9 grid h-[424px] grid-cols-4  gap-4">
          <For each={model()?.items}>
            {(item) => (
              <Show
                when={item.image}
                fallback={
                  <div
                    onClick={() => navigate(`/company-website/property-detail/${item.id}`)}
                    class="relative flex h-[424px] cursor-pointer gap-[6px] bg-[#E7ECF6] bg-center bg-no-repeat p-5"
                    style={{ 'background-image': `url(${IconDefaultUnit})` }}>
                    <Show when={item.pinned}>
                      <div class="flex h-[26px]  items-center justify-center rounded-[46px]  bg-danger px-[10px] py-1 text-xs text-white ">
                        {PINNED}
                      </div>
                    </Show>
                    {Bottom(item.address ?? '', item.area ?? '')}
                  </div>
                }>
                <div
                  onClick={() => navigate(`/company-website/property-detail/${item.id}`)}
                  class="relative flex h-[424px] cursor-pointer items-center gap-3 bg-cover bg-no-repeat"
                  style={{ 'background-image': `url(${item.image})` }}>
                  {Bottom(item.address ?? '', item.area ?? '')}
                </div>
              </Show>
            )}
          </For>
        </div>
      </div>
    </div>
  );
};
