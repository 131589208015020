import { Component, mergeProps, JSX } from 'solid-js';
import { Button, ButtonProps } from './Button';

type LinkButtonProps = ButtonProps &
  JSX.AnchorHTMLAttributes<any> & {
    href: string;
  };

export const LinkButton: Component<LinkButtonProps> = Button;

export const GrayLinkButton: Component<LinkButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      class:
        'w-full border-text-level03 text-text-level01 hover:border-primary-color hover:text-primary-color py-2 px-5 text-sm leading-normal',
      variant: 'outlined',
    } as LinkButtonProps,
    originProps
  );

  return <LinkButton {...props} />;
};

export const WhiteLinkButton: Component<LinkButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      class:
        'z-[10] w-fit border-solid text-white border-white hover:border-slate-100 hover:bg-slate-100/10 hover:text-slate-100 text-xs px-4 py-2.5',
      variant: 'outlined',
      rounded: 'md',
    } as LinkButtonProps,
    originProps
  );

  return <LinkButton {...props} />;
};
