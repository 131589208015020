import { companyUnitListingGateway } from '~/gateways/companyUnitListingGateway';
import { UnitParams } from '~/types/Unit';
import { MagicUseCase } from '../magicUseCase';

export class GetPinnedUnitsUseCase extends MagicUseCase {
  protected async runLogic(params: UnitParams) {
    const response = await companyUnitListingGateway.getCompanyUnitsListing(params);
    this.getState().user.pinnedUnits = response;
  }
}
